<script setup>
import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import {useField, useForm} from "vee-validate";

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  reference: {
    type: String,
    default: 'inputEl'
  },
  modelValue: {
    type: [String, Number, Boolean, Array, Object],
    default: ''
  },
  maxlength: {
    type: [String, Number, Boolean],
    default: false
  },
  country:{
    type: [Number,String],
    default: 1
  },
  required: Boolean,
  borderless: Boolean,
  borderbottom: Boolean,
  borderradiusnone: Boolean,
  transparent: Boolean,
  disabled: Boolean,
})

const emit = defineEmits(['update:modelValue', 'right-icon-click'])

const computedValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:modelValue', value)
  }
})

const inputElClass = computed(() => {
  const base = [
    'px-3 py-2 max-w-full focus:ring focus:outline-none border-gray-400 rounded w-full',
    'dark:placeholder-gray-400',
    computedType.value === 'textarea' ? 'h-24' : 'h-10',
    props.borderless ? 'border-0' : 'border',
    props.borderbottom ? 'border-b border-stone-900' : 'border',
    props.borderradiusnone ? 'rounded-none' : '',
    props.transparent ? 'bg-transparent' : 'bg-white dark:bg-gray-800',
    props.disabled ? 'bg-gray-200' : ''
  ]

  if (props.icon) {
    base.push('pl-10')
  }

  return base
})

const computedType = computed(() => props.options ? 'select' : props.type)

const controlIconH = computed(() => props.type === 'textarea' ? 'h-full' : 'h-12')

const inputEl = ref(null)

const name = props.name;
const {
  Value: value,
  errorMessage,
  meta,
} =  useField(name, undefined, {
  initialValue: props.modelValue,
});

watch(computedValue, (oldVal,newVal) => {
  if(computedType.value === 'tel' && computedValue.value !== null){
    let r = computedValue.value.replace(/\D/g,"");
    r = r.replace(/^0/,"");

    if(props.country === 1 || props.country === 2 || props.country === 'US' || props.country === 'CA')
    {
      if (r.length >= 10) {
        // 11+ digits. Format as 5+4.
        r = r.replace(/^(\d\d\d)(\d{3})(\d{4}).*/,"$1-$2-$3");
      }
      else if (r.length > 6) {
        // 6..10 digits. Format as 4+4
        r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/,"$1-$2-$3");
      }
      else if (r.length === 3 ) {
        // check if input has parens - skip reformatting if so
        if(!computedValue.value.match(/\(/) ){
          // 3..5 digits. Add (0XX..)
          r = r.replace(/^(\d\d\d)(\d{0,3})/,"$1");
        }
      }
      else if (r.length > 3) {
        // 3..5 digits. Add (0XX..)
        r = r.replace(/^(\d\d\d)(\d{0,3})/,"$1-$2");
      }
    }
    emit('update:modelValue', r)
    // computedValue.value = r;
  }
})
</script>
<template>
  <div class="relative">
    <V-Field
        v-if="computedType === 'select'"
        v-model="computedValue"
        :name="name"
        :class="inputElClass"
        as="select"
        :disabled="disabled"
    >
        <option
            v-for="option in options"
            :key="option.id ?? option"
            :value="option.id"
        >
          {{ option.name }}
        </option>
    </V-Field>

    <textarea
      v-else-if="computedType === 'textarea'"
      :id="id"
      v-model="computedValue"
      :class="inputElClass"
      :name="name"
      :placeholder="placeholder"
      :required="required"
    />
    <V-Field
        v-else-if="computedType === 'tel'"
        ref="inputEl"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :type="computedType"
        :class="inputElClass"
        :disabled="disabled"
        :value="computedValue"
    />
    <V-Field
        v-else
        :ref="reference"
        v-model="computedValue"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :type="computedType"
        :class="inputElClass"
        :disabled="disabled"
        :maxlength="maxlength"
    />
    <front-control-icon
      v-if="icon"
      :icon="icon"
      :h="controlIconH"
    />
    <p class="text-red-600" v-show="(errorMessage || meta.valid)">
      {{ errorMessage }}
    </p>
  </div>
</template>
